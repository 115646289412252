import { Container, Text } from '@nextui-org/react';
import PageContainer from 'components/pageContainer';
import Link from 'next/link';

export default function NotFound() {
  return (
    <PageContainer
      title="404 – Rikhil Raithatha"
      css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Container display="flex" direction="column" alignItems="center">
        <Text h1>404</Text>
        <Text b>Sorry! Page not found</Text>
        <Link href="/" aria-label="Homepage">
          Return Home
        </Link>
      </Container>
    </PageContainer>
  );
}
